var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"66b020fd4fe268eb32e64b277d434b1da136ffdf"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole } from '@sentry/integrations';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://945627eebd054022ac0fbf31af0d4375@o4504935759740928.ingest.sentry.io/4504935762624512',
  integrations: [
    new CaptureConsole({
      levels: ['error']
    })
  ],
  tracesSampleRate: 1.0,
});
